<template>
  <div>
    <div class="container" style="font-size: 1.5625vw; font-weight: 500">
      产品说明书
    </div>
    <div class="top">
      <p>
        <u
          ><a :href="`${baseUrl}pdf/Y01A.pdf`" target="_blank">智能指纹密码器Y01A说明书</a></u
        >
      </p>
      <p>
        <u
          ><a :href="`${baseUrl}pdf/Y03B.pdf`" target="_blank">智能指纹办公抽屉柜锁Y03B说明书</a></u
        >
      </p>
      <!-- <p>
        <u
          ><a :href="`${baseUrl}pdf/Y02L.pdf`" target="_blank">Y02L产品说明书</a></u
        >
      </p> -->
      <p>
        <u
          ><a :href="`${baseUrl}pdf/Y03L.pdf`" target="_blank">智能指纹办公抽屉柜锁Y03L说明书</a></u
        >
      </p>
    </div>
  </div> 
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.BASE_URL || '/',
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 4rem 0;
  text-align: center;
  .top {
    display: flex; 
    flex-flow: wrap;
    // justify-content: center;
    p {
      width: 50%;
      text-align: center;
      margin: 1.025rem 0;
      font-size: 1.3542vw;
    }
  }
}
</style>
